.blog-section {
  padding-top: 180px;
  padding-bottom: 50px;

  @media screen and (min-width: $laptop) {
    max-width: $laptop;
    margin: auto;
  }

  .markdown-toggle {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 9;
  }

  .markdown {
    border: 1px solid #ccc;
  }

  .container-60 {
    position: relative;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;

    .blog-post-heading {
      display: flex;
      gap: 20px;
      align-items: center;

      a {
        display: flex;
        text-decoration: none;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 80%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .markdown {
      border: none;
      width: 100%;
      padding: 20px 0;
    }

    .blog-list {
      display: grid;
      grid-template-columns: repeat(4, minmax(200px, 450px));
      margin-right: -10px;
      margin-left: -10px;
      justify-content: space-between;

      @media screen and (max-width: $tab-port) {
        grid-template-columns: repeat(3, minmax(200px, 320px));
      }

      @media screen and (max-width: $tab-port - 200) {
        grid-template-columns: repeat(2, minmax(200px, 320px));
      }

      @media screen and (max-width: $phone - 150) {
        grid-template-columns: 100%;
      }

      .blog-list-item {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 40px;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-20px);
        }

        .blog-list-link {
          color: $color-dark;
          padding-bottom: 40px;
          text-decoration: none;

          p {
            font-weight: 300;
            margin-bottom: 10px;
          }

          .blog-img {
            height: 320px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 20px;
          }

          .blog-item-date {
            margin-bottom: 10px;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
          }

          .blog-item-description {
            color: $color-dark-grey;
          }

          .blog-item-author {
            margin-bottom: 30px;
            font-weight: 700;
          }
        }
      }
    }

    .blog-post {
      p {
        font-weight: 300;
        margin-bottom: 10px;
      }

      .blog-img {
        height: 420px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 10px;

        @media screen and (max-width: $tab-port) {
          height: 320px;
        }
      }

      .blog-img-caption {
        font-style: italic;
        text-align: center;
        margin-bottom: 10px;
      }

      .blog-item-date-comments {
        margin-bottom: 30px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $color-dark-grey;
        justify-content: flex-start;
        gap: 2px 10px;

        .item-time-comments {
          text-transform: lowercase;
          letter-spacing: normal;
        }
       :last-child {
        text-transform: capitalize;
      }
    }

      .blog-item-author {
        margin-bottom: 10px;
        font-weight: 700;
      }
    }

    .blog-post-heading {
      display: flex;
      gap: 20px;
      align-items: center;

      a {
        display: flex;
        text-decoration: none;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }

  .upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    justify-content: center;

    img {
      margin-top: 30px;
      max-width: 100%;
      max-height: 500px;
    }
  }

  .edit-post-actions {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $phone) {
      flex-direction: column;
    }
  }
}

.slick-slider {
  // max-width: $tab-land;
  margin: auto;
}

.item-container {
  padding: 6px 7px;
  margin-bottom: 30px;

  .item {
    position: relative;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    color: $color-dark;

    &:hover {
      opacity: 0.8;
    }

    .image {
      position: relative;
      height: 200px;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }

      .text {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: rgb(180, 180, 180);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        color: #fff;
      }
    }

    .info {
      margin-top: 20px;

      h4 {
        margin-top: 10px;
        font-size: 1.1rem;
      }
    }
  }
}

.carousel-arrow {
  position: absolute;
  top: calc(50% - 32px);
  z-index: 99;
  background: #fff none repeat scroll 0 0 !important;
  border-radius: 30px !important;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  color: #000 !important;
  height: 40px;
  line-height: 40px;
  margin: 0 !important;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.img-placeholder {
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  margin-top: -4px;
  background-color: #e9e9e9;
}
