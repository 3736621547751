input {
  border: none;
  height: 48px;
  width: calc(100% - 56px);
  font-size: 1rem;
  border-radius: 100px;
  padding: 0 28px;
  color: $color-dark;
  font-family: $CodeFont;

  &.dark {
    border: 1px solid #cccccc;
    transition: all 0.2s ease;

    &:focus {
      border: 1px solid #3898ec;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

textarea {
  border: none;
  resize: none;
  width: calc(100% - 56px);
  font-size: 1.1rem;
  border-radius: 6px;
  padding: 10px 28px;
  color: $color-dark;
  font-family: $CodeFont;
  tab-size: 4;
  transition: border 0.2s ease;

  &.dark {
    border: 1px solid #cccccc;

    &:focus {
      border: 1px solid #3898ec;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.form-title {
  font-family: $CodeFont;
  color: $color-dark-grey;
}
