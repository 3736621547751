*,
*:not(img),
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  max-width: 2400px;
  margin: auto;
  background: $color-white;
  font-size: 16px;
  color: $color-dark;

  navbar__checkbox:checked {
    border: 2px solid red;
  }
}

::selection {
  color: #fff;
  background: rgba(#527982, 0.7);
  // background: rgba($color-dark, 0.7);
}

@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 100ms 100ms ease, transform 400ms ease;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transition: opacity 100ms 100ms ease, transform 400ms ease;
  }
}

@keyframes zoomOutAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.zoomIn {
  animation: zoomInAnimation 0.3s ease;
}

.zoomOut {
  animation: zoomOutAnimation 0.3s ease;
}

.Toastify__toast {
  padding: 10px !important;
  min-height: 24px !important;
  border-radius: 10px !important;
}

.Toastify__toast-body {
  font-family: $PrimaryFont;
  font-size: 0.9rem;
}

.member {
  margin-top: 20px;
  display: flex;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  textarea {
    font-size: 1rem;
    height: 280px;
  }
}

.markdown-container {
  .wmde-markdown {
    font-family: $SerifFont;
  }
}

.wmde-markdown:not(.w-md-editor-preview) {
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 1.8;
  border-radius: 6px;
  padding: 20px 0;
  color: $color-dark;
  font-family: $PrimaryFont;

  img {
    display: flex;
    margin: auto;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: $tab-port) {
    padding: 10px 18px;
    width: calc(100% - 36px);
  }

  @media screen and (max-width: $phone) {
    padding: 0;
    width: 100%;
  }
}

.wmde-markdown blockquote,
.wmde-markdown details,
.wmde-markdown dl,
.wmde-markdown ol,
.wmde-markdown p,
.wmde-markdown pre,
.wmde-markdown table,
.wmde-markdown ul {
  margin-bottom: 20px;
  margin-top: 0;
}

.wmde-markdown h1,
.wmde-markdown h2,
.wmde-markdown h3,
.wmde-markdown h4,
.wmde-markdown h5,
.wmde-markdown h6 {
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 24px;
}

.wmde-markdown {
  blockquote,
  > blockquote blockquote {
    color: #61676f !important;
    background-color: #eef0f3;
    padding: 1rem !important;
  }
  li {
    margin-bottom: 16px;
    margin-top: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.calendly-badge-widget .calendly-badge-content {
  font-family: "PitchSans", sans-serif !important;
}
.calendly-badge-widget .calendly-badge-content span {
  display: none !important;
}

.notfound {
  padding: 1.8rem;
}

