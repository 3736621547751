.footer {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    font-weight: 300;

    .footer__menu {
      margin-bottom: 80px;
      gap: 100px;
      display: flex;

      @media screen and (max-width: $tab-port) {
        flex-direction: column;
        gap: 20px;
      }

      @media screen and (max-width: $phone) {
        margin-bottom: 50px;
      }

      .footer-menu-item {
        display: inline-block;
        margin-right: 100px;

        p,
        a {
          margin-bottom: 10px;
          display: block;

          &:hover {
            color: $color-dark-grey;
          }
        }

        &__title {
          text-transform: uppercase;
          letter-spacing: 0.14rem;
          font-size: 12px;
          color: $color-dark-grey;
        }

        @media screen and (max-width: $tab-port) {
          margin-right: 50px;
        }

        @media screen and (max-width: $phone) {
          display: block;
          margin-bottom: 50px;
        }
      }
    }

    .footer__text {
      display: block;

      @media screen and (max-width: $phone) {
        flex-direction: column-reverse;
        gap: 50px;
      }
    }

    .footer-links {
      margin-top: 2px;
      display: flex;
      gap: 10px;

      a:hover {
        color: $color-dark-grey;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }

    @media screen and (max-width: $phone) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
