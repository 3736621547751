.section-home-hero {
  font-size: 20px;
  line-height: 1.2rem;
  font-weight: 300;
  background: $color-white;
  position: relative;
  padding-top: 190px;
  margin-bottom: 80px;

  @media screen and (max-width: $tab-port) {
    padding-top: 150px;
    margin-bottom: 0;
    overflow: hidden;
  }

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .home-hero-text {
    width: 55%;
    padding-right: 30px;
 

    @media screen and (max-width: $tab-port) {
      width: 100%;
      margin: 0;
    }
    
    .home-hero-h1-wrapper,
    .home-hero-h2-wrapper {
      position: relative;
      overflow: hidden;

      &.multiple {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      .home-hero-h1 {
        margin-bottom: 0;
        line-height: 1.2;
        
        a {
          line-height: 1.4;
          
          svg{
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .color-blue {
        span {
          color: $color-white;
          background: #0c2647;
          padding: 4px;
          line-height: 1.4;
        }
      }
      

      .home-hero-p {
        font-weight: 300;
        line-height: 1.5;
        margin: 20px 0;
      }
    }

    .home-hero-h2-wrapper {
      margin: 20px 0;
    }
  }

  .home-hero-image-top-container {
    flex: 1 1 50%;
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tab-port) {
      display: none;
    }

    .home-hero-image-inner-container {
      height: 730px;
      left: -60px;
      padding: 40px 0 0 0;

      &.__absolute-layout {
        position: absolute;
        width: 660px;

        .perspective {
          position: relative;
          width: 600px;
          perspective: 700px;
          height: 500px;

          .rotated-flex-container {
            display: flex;
            transform: rotate3d(0.5, -0.866, 0, 15deg) rotateZ(-1deg);
            border-radius: 4px;
            position: relative;

            .flex-column {
              display: flex;
              flex-direction: column;
              flex: 1 1 90%;
              width: 50%;
              perspective: 700px;

              .svg-container {
                margin: 5px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                outline: 1px solid transparent;

                &.top-left-m5-flex {
                  margin: 0 5px 5px 0;
                  flex: 1 1 60%;
                  animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1
                      normal forwards running slideUp,
                    1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards
                      running fadeIn;

                  .cls-1 {
                    fill: #e4e4f2;
                  }
                  .cls-2 {
                    fill: #fff;
                  }
                  .cls-3 {
                    font-size: 17px;
                    fill: #434343;
                    font-weight: 600;
                  }
                  .cls-4 {
                    fill: #99cf8b;
                  }
                  .cls-5 {
                    fill: #928fc6;
                  }
                  .cls-6 {
                    fill: #eae787;
                  }
                  .cls-7 {
                    fill: #ce8d8d;
                  }
                  .cls-8 {
                    fill: #e8c16c;
                  }
                }

                &.bottom-left-m5-flex {
                  margin: 5px 5px 0 0;
                  box-shadow: 0px 27.5px 40px -17.5px rgba(0, 0, 0, 0.4),
                    46.2px 37.5px 40px -17.5px rgba(0, 0, 0, 0.4);
                  flex: 1 1 60%;
                  animation: 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running slideUp, 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running fadeIn;

                  .cls-1,
                  .cls-7 {
                    fill: #e4e4f2;
                  }
                  .cls-2 {
                    fill: #fff;
                  }
                  .cls-3 {
                    font-size: 17px;
                    fill: #434343;
                    font-weight: 600;
                  }
                  .cls-4 {
                    fill: #8ab8af;
                  }
                  .cls-5 {
                    fill: #99d6cc;
                  }
                  .cls-6 {
                    fill: #d0ebe5;
                  }
                  .cls-7 {
                    opacity: 0.5;
                  }
                  .cls-10,
                  .cls-11,
                  .cls-12,
                  .cls-13,
                  .cls-8,
                  .cls-9 {
                    fill: none;
                  }
                  .cls-8 {
                    stroke: #fff;
                    stroke-width: 10px;
                  }
                  .cls-12,
                  .cls-13,
                  .cls-8 {
                    stroke-linecap: round;
                  }
                  .cls-13,
                  .cls-8 {
                    stroke-linejoin: round;
                  }
                  .cls-13,
                  .cls-9 {
                    stroke: #e8c16c;
                  }
                  .cls-10,
                  .cls-11,
                  .cls-12,
                  .cls-9 {
                    stroke-miterlimit: 10;
                  }
                  .cls-10,
                  .cls-11,
                  .cls-12,
                  .cls-13,
                  .cls-9 {
                    stroke-width: 5px;
                  }
                  .cls-10,
                  .cls-12 {
                    stroke: #928fc6;
                  }
                  .cls-11 {
                    stroke: #eae787;
                  }
                  .cls-14 {
                    fill: #928fc6;
                  }
                  .cls-15 {
                    fill: #eae787;
                  }
                  .cls-16 {
                    fill: #99cf8b;
                  }
                  .cls-17 {
                    fill: #e8c16c;
                  }
                  .cls-18 {
                    fill: #face3b;
                  }
                  .cls-19 {
                    fill: #ce8d8d;
                  }
                }

                &.top-right-m5-flex {
                  margin: 0 0 5px 5px;
                  box-shadow: 0px 0px 40px -17.5px rgba(0, 0, 0, 0.4),
                    46.2px 57.5px 70px -7.5px rgba(0, 0, 0, 0.5);
                  flex: 1 1 40%;
                  animation: 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running slideUp, 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running fadeIn;

                  .cls-1 {
                    fill: #e4e4f2;
                  }
                  .cls-2 {
                    fill: #fff;
                  }
                  .cls-3 {
                    fill: #face3b;
                  }
                  .cls-4 {
                    fill: #99cf8b;
                  }
                  .cls-5 {
                    font-size: 17px;
                    fill: #434343;
                    font-weight: 600;
                  }
                  .cls-6 {
                    fill: #928fc6;
                  }
                  .cls-7 {
                    fill: #eae787;
                  }
                  .cls-8 {
                    fill: #ce8d8d;
                  }
                  .cls-9 {
                    fill: #e8c16c;
                  }
                  .cls-10 {
                    fill: #99d6cc;
                  }
                }

                &.bottom-right-m5-flex {
                  margin: 5px 0 0 5px;
                  box-shadow: 0px 37.5px 40px -17.5px rgba(0, 0, 0, 0.4),
                    46.2px 37.5px 70px -7.5px rgba(0, 0, 0, 0.5);
                  flex: 1 1 40%;
                  animation: 4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running slideUp, 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 normal forwards running fadeIn;

                  .cls-1 {
                    fill: #e4e4f2;
                  }
                  .cls-2 {
                    fill: #fff;
                  }
                  .cls-3 {
                    font-size: 17px;
                    fill: #434343;
                    font-weight: 600;
                  }
                  .cls-4 {
                    fill: #99cf8b;
                  }
                  .cls-5 {
                    fill: #eae787;
                  }
                  .cls-6 {
                    fill: #928fc6;
                  }
                  .cls-7 {
                    fill: #777ebd;
                  }
                  .cls-8 {
                    fill: #e8c16c;
                  }
                  .cls-9 {
                    fill: #fbbf46;
                  }
                  .cls-10 {
                    font-size: 9px;
                    fill: #444545;
                  }
                  .cls-11 {
                    letter-spacing: -0.01em;
                  }
                  .cls-12 {
                    letter-spacing: 0em;
                  }
                  .cls-13 {
                    letter-spacing: -0.01em;
                  }
                  .cls-14 {
                    letter-spacing: 0.01em;
                  }
                  .cls-15 {
                    letter-spacing: -0.01em;
                  }
                  .cls-16 {
                    letter-spacing: 1.75em;
                  }
                  .cls-17 {
                    letter-spacing: 3.79em;
                  }
                  .cls-18 {
                    letter-spacing: 0.03em;
                  }
                  .cls-19 {
                    letter-spacing: 0em;
                  }
                  .cls-20 {
                    letter-spacing: -0.01em;
                  }
                  .cls-21 {
                    letter-spacing: -0.11em;
                  }
                  .cls-22 {
                    letter-spacing: -0.01em;
                  }
                  .cls-23 {
                    letter-spacing: 2.78em;
                  }
                  .cls-24 {
                    letter-spacing: -0.11em;
                  }
                  .cls-25 {
                    letter-spacing: 2.99em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .home-image {
    margin-bottom: auto;

    @media screen and (max-width: $tab-port) {
      margin: auto;
      width: 60%;
    }

    @media screen and (max-width: $phone) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

.home-section-service {
  text-align: center;
  padding: 100px 50px 0;
}

.home-section-stacks {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }

    .home-how-it-works {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      gap: 20px;
      margin-top: 50px;
      padding: 1.6rem;
      background-color: #e8ebf4;
      border-radius: 10px;

      @media screen and (max-width: $tab-port) {
        grid-template-columns: 1fr 1fr;
        border-radius: 0;
      }

      @media screen and (max-width: $phone) {
        grid-template-columns: 1fr;
        gap: 30px;
      }

      h3 {
        @extend .color-blue;
        padding: 10px 0;
      }
    }
  }
}

.home-section-stacks-introduction {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 50px;

    @media screen and (max-width: $tab-land) {
      grid-template-columns: 1fr;
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }

    .home-section-introduction {
      ul {
        margin-left: 15px;
      }

      li {
        margin: 10px 0;
      }

      p {
        margin: 20px 0;
        // @extend .color-grey;
      }
    }

    .home-section-benefit {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .home-benefit-text-wrapper {
        position: absolute;
        z-index: 1;
        text-align: center;
        width: 100%;

        @media screen and (max-width: $tab-land) {
          position: relative;
        }

        h3 {
          margin-top: 0px;
          margin-bottom: 30px;
        }

        .benefits {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            display: flex;
            width: calc(100% - 40px);
            font-weight: 500;
            background: #fff;
            text-align: left;
            margin-bottom: 10px;
            height: 50px;
            padding: 10px;
            align-items: center;
            border-radius: 10px;
            color: $color-blue;

            &.indentation {
              margin-left: 40px;
            }

            @media screen and (max-width: 1068px) {
              font-size: 0.9rem;
            }

            @media screen and (max-width: $tab-land) {
              width: 60%;
            }

            @media screen and (max-width: $tab-port) {
              width: 80%;
            }

            @media screen and (max-width: $phone) {
              width: calc(100% - 40px);
            }
          }
        }
      }
    }
  }
}

.home-section-machine {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      grid-template-columns: 1fr;
      max-width: 100%;

      img {
        width: 90%;
        margin: auto;
      }
    }

    @media screen and (max-width: $phone) {
      img {
        width: 100%;
      }
    }
  }
}

.home-section-similar-law {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      grid-template-columns: 1fr;
      max-width: 100%;

      img {
        width: 90%;
        margin: auto;
      }
    }

    @media screen and (max-width: $phone) {
      img {
        width: 100%;
      }
    }
  }
}

.home-section-economic-gains {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    li {
      margin: 10px 0;
    }

    img {
      width: 100%;
    }

    ul {
      margin-left: 15px;
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      grid-template-columns: 1fr;
      max-width: 100%;

      img {
        width: 90%;
        margin: auto;
      }
    }

    @media screen and (max-width: $phone) {
      img {
        width: 100%;
      }
    }
  }
}

.home-section-divider-text {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    text-align: center;

    h1 {
      margin: 0;
    }
  }
}

.home-section-divider {
  padding: 50px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    text-align: center;

    .divider {
      height: 5px;
      background: #7b9bfb;
      width: 100%;
    }
  }
}

.home-section-infringement-text {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h1 {
      margin: 0;
    }
  }
}

.home-section-contact {
  padding: 100px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contact-sub-heading {
      width: 60%;
      text-align: center;

      @media screen and (max-width: $tab-land) {
        width: 80%;
      }

      @media screen and (max-width: $phone) {
        width: 100%;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      grid-template-columns: 1fr;
      max-width: 100%;
    }
  }
}

.home-section-developer {
  padding: 180px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: $tab-port) {
      flex-direction: column;
      align-items: flex-start;
      gap: 80px;
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $phone) {
      max-width: 100%;
    }

    .developer-first-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      h2,
      p,
      li {
        color: $color-white;
        font-weight: 300;
      }

      p,
      ul {
        font-size: 1.1rem;
        margin-bottom: 30px;
      }

      ul {
        margin-left: 16px;
      }
    }
  }
}

.contact-form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  .form-row {
    display: flex;
    width: 100%;
    gap: 20px;

    @media screen and (max-width: $phone) {
      flex-direction: column;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(0px, 80px, 0px);
  }
  to {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
