.layout {
  color: $color-dark;
  background: $color-white;
  overflow: hidden;

  @media screen and (max-width: $tab-port) {
    padding: 0 25px; // same as navbar
  }

  &__dark {
    background: $color-dark;
    color: $color-white;
    min-height: 100%;

    a {
      color: $color-white;
    }
  }

  &__darker {
    background-color: $color-dark;
    background-image: linear-gradient(147deg, $color-dark 0%, #1e2022 74%);
    color: $color-white;
    min-height: 100%;
  }

  &__full {
    min-height: 100vh;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
}
