.get-started {
  min-height: 600px;
  max-height: 800px;
  position: relative;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;

  &__background {
    position: absolute;
    width: 100%;
    height: 600px;
    margin: auto;
  }

  .container-60 {
    position: absolute;
    z-index: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    display: flex;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
    gap: 30px;
    padding: 0 10px;

    @media screen and (max-width: $tab-land) {
      max-width: 80%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 90%;
    }

    @media screen and (max-width: $phone) {
      max-width: 100%;
    }

    .get-started-h1 {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 300;

      @media screen and (max-width: $tab-port) {
        font-size: 1.1rem;
      }

      @media screen and (max-width: $phone) {
        font-size: 1rem;
      }
    }
    
  }
}
