.about-section {
  padding: 140px 0;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .about-hero {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 80px;
      padding-top: 80px;
      max-width: 1080px;
      margin: auto;

      img {
        width: 50%;
        border-radius: 12px;
      }

      .about-hero-text {
        width: 100%;

        h4 {
          margin-top: 30px;
        }

        p {
          font-size: 1.1rem;
          margin-bottom: 20px;
          font-weight: 300;
        }
      }

      @media screen and (max-width: $tab-land) {
        img {
          width: 60%;
        }
      }

      @media screen and (max-width: $tab-port) {
        flex-direction: column;
        justify-content: center;
        width: 80%;

        img {
          width: 80%;
          margin: auto;
        }
      }

      @media screen and (max-width: $phone) {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .team-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 32%);
      justify-content: space-between;
      gap: 1rem;

      @media screen and (max-width: $tab-port) {
        grid-template-columns: repeat(2, 49%);
      }

      @media screen and (max-width: $phone) {
        grid-template-columns: 1fr;
        gap: 20px;
      }

      .team {
        height: 300px;
        background: #2b2e30;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0;

        .team-img {
          border-radius: 50%;
          width: 200px;
          height: 200px;
        }

        .team-name {
          margin-top: 20px;
          font-size: 1.2rem;
        }

        .team-title {
          margin-top: 5px;
          font-size: 0.9rem;
          color: #909296;
        }

        .social a {
          margin: 0 0.4rem;
        }

        .social a svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }
}
