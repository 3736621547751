.login {
  padding-top: 180px;
  padding-bottom: 50px;

  .container-60 {
    position: relative;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: $tab-land) {
      max-width: 80%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }
}
