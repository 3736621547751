.developer-section {
  padding-top: 180px;
  padding-bottom: 50px;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .terminal {
      margin-top: 100px;
    }

    .quick-start-guide {
      margin-top: 100px;
      border-radius: 8px;
      background: #fff;
      padding: 100px;

      @media screen and (max-width: $tab-port) {
        padding: 50px;
      }

      @media screen and (max-width: $phone) {
        padding: 20px;
      }

      &__guide {
        margin: 40px 0;

        p {
          color: $color-dark-grey;
          font-weight: 300;
        }
      }
    }

    .developer-section-tech {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;
      gap: 50px;

      .tech-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        img {
          width: 50px;
          margin-bottom: 10px;
        }

        h3 {
          margin-bottom: 30px;
        }

        p {
          font-weight: 300;
          color: $color-dark-grey;
          margin-bottom: 60px;
        }
      }

      @media screen and (max-width: $tab-port) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;

      .terminal {
        margin-top: 60px;
      }
    }
  }
}

.terminal {
  color: $color-white;
  background: #000;
  min-height: 200px;
  border-radius: 8px;
  width: 100%;

  .indent {
    margin-left: 40px;
  }

  .color-yellow {
    color: #e6db74;
  }

  .color-pink {
    color: #f92672;
  }

  @media screen and (max-width: $tab-port) {
    width: 100%;
  }

  .terminal-header {
    height: 30px;
    margin: 20px;
    margin-bottom: 0;
    padding-top: 10px;
    border-bottom: 1px solid $color-dark-grey;

    .terminal-circles {
      display: flex;
      align-items: center;
      height: 30px;
      gap: 5px;

      .terminal-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $color-dark-grey;
        background: $color-dark;
      }
    }
  }

  .terminal-body {
    padding: 40px;
    line-height: 1.3;
    font-family: $CodeFont;
    font-size: 0.9rem;
    span {
      font-weight: 500;
    }

    @media screen and (max-width: $tab-port) {
      font-size: 0.8rem;
    }
  }
}
