// FONT FAMILY
$PrimaryFont: "Poppins", sans-serif;
$CodeFont: "Source Code Pro","Helvetica","Arial", sans-serif;
$SerifFont: "IBM Plex Sans", serif;

// COLORS
$color-dark: #121313;
$color-white: #f4f4f4;
$color-blue: #0c2647;
$color-dark-grey: #555;

// FONT SIZES
$default-font-size: 1rem;

// GRID
$grid-width: 114rem; // 1140px standard width
$gutter-vertical: 4rem; // 40px Gutter Y
$gutter-vertical-small: 3rem; // 30px Gutter Y
$gutter-horizontal: 2rem; // 20px Gutter X

// MEDIA QUERIES
$laptop: 1280px;
$tab-land: 1200px;
$tab-port: 900px;
$phone: 600px;
