@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");

.section {
  position: relative;
  width: 100%;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.modal {
  position: fixed;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: $color-dark;
  pointer-events: none;
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}
.modal-btn:checked ~ .modal {
  pointer-events: auto;
  opacity: 1;
  transition: opacity 350ms ease-in;
}

.model-icon-wrap {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  @media screen and (max-width: $phone) {
    top: 15px;
    right: 15px;
  }
}

.modal-icon {
  // Create Lines
  &,
  &::before,
  &::after {
    margin-top: 20px;
    width: 3rem;
    height: 2px;
    background-color: $color-dark;
    display: inline-block;

    @media screen and (max-width: $phone) {
      width: 2rem;
    }
  }

  & {
    background-color: transparent;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }

  &::before {
    top: 0;
    transform: rotate(135deg);
  }

  &::after {
    top: 0;
    transform: rotate(-135deg);
  }
}

.modal-wrap {
  position: relative;
  display: block;
  width: 100%;
  max-width: 350px;
  min-height: 300px;
  border-radius: 30px;
  margin: 0 10px;
  overflow: hidden;
  padding: 90px 70px 50px;
  background-color: #fff;
  -ms-flex-item-align: center;
  align-self: center;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 100ms 100ms ease, transform 400ms ease;

  @media screen and (max-width: $phone) {
    padding: 60px 40px 20px;
  }
}
.modal-btn:checked ~ .modal .modal-wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms 100ms ease, transform 400ms ease;
}
