.social {
  display: flex;

  svg {
    height: 30px;
    width: 30px;
  }

  &__left {
    justify-content: flex-start;
  }

  &__right {
    justify-content: flex-end;
  }

  &__left a,
  &__left button {
    margin-right: 0.4rem;
  }

  &-footer__left a,
  &-footer__left button {
    margin-right: 0.8rem;
  }

  &__right a,
  &__right button {
    margin-left: 0.4rem;
  }

  @media screen and (max-width: $phone) {
    margin-left: -2px;
  }
}
