.button {
  @extend .no-select;
  display: inline-block;
  cursor: pointer;
  color: $color-white;
  background: $color-dark;
  border: 1px solid $color-dark;
  border-radius: 100px;
  padding: 14px 28px;
  transition: background 0.2s ease-in-out;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  font-family: $CodeFont;

  &:hover {
    color: $color-dark;
    background: transparent;
    transition: background 0.2s ease-in-out;
  }

  &--white {
    color: $color-dark;
    background: $color-white;

    &:hover {
      color: $color-white;
      background: $color-dark;
      transition: background 0.2s ease-in-out;
    }
  }

  &--dark {
    border: 1px solid $color-white;
    color: $color-white;
    background: $color-dark;

    &:hover {
      color: $color-dark;
      background: $color-white;
      transition: background 0.2s ease-in-out;
    }
  }

  &--danger {
    border: 1px solid crimson;
    color: $color-white;
    background: crimson;

    &:hover {
      color: crimson;
      background: $color-white;
      transition: background 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: $phone) {
    font-size: 0.9rem;
  }
}

.button[disabled] {
  opacity: 0.5;
  pointer-events: none;
  box-shadow: none;
}
