.solutions-section {
  padding-top: 180px;
  // padding-bottom: 50px;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .service-h1 {
      margin-bottom: 50px;
      text-align: center;
    }

    h4 {
      margin-bottom: 0;
    }

    .feature {
      display: flex;
      align-items: center;
      gap: 50px;
      padding: 30px 0;
      color: $color-dark-grey;
      font-weight: 300;

      img {
        width: 70px;
        height: 70px;
      }

      @media screen and (max-width: $phone) {
        flex-direction: column;
      }

      a, p {
        font-size: 1.3rem;

        @media screen and (max-width: $tab-port) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: $phone) {
          font-size: 1.1rem;
        }
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }
  iframe{
    height: 100vh;
    body > div.main-page.bubble-element.Page > div:nth-child(1){
      display: none;
    }
  }
}
