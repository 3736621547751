.contact-section {
  padding-top: 180px;
  padding-bottom: 50px;

  .container-80 {
    position: relative;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .get-in-touch {
      text-align: center;

      > div a {
        color: $color-white;
        transition: color 0.2s ease;
        &:hover {
          color: $color-dark-grey;
          cursor: pointer;
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;

        a {
          text-decoration: none;
        }

        img {
          width: 24px;
          height: 24px;
          margin-top: 4px;
        }

        gap: 10px;
      }
    }

    @media screen and (max-width: $tab-land) {
      max-width: 90%;
    }

    @media screen and (max-width: $tab-port) {
      max-width: 100%;
    }
  }
}
